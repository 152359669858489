import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { device } from '../rules/device';
import styled from 'styled-components';
import Icon from './Icon';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobileL} {
    padding: 1rem;
  }
`;

const Svg = styled(Icon)`
  width: 40px;
  height: 40px;
  transition: all 0.2s ease-in-out;
`;

const Logo = ({ className }) => ( 
  <Svg viewBox="0 0 100 100" className={className}>   
    <path 
      fill="currentColor" 
      d="M92.225,33.085c-7.643,2.316-61.967,6.9-46.895-2.716,15.9-10.12-27.349-8.895-19.759-13.608C36.249,10.1,20.138-1.88,10.791.25,9.886.437,9.14.889,8.874,3.739,8.688,5.949,8.555,7.2,8.5,7.893a1.2,1.2,0,0,1-.026.133v.16l-.027.026v.107L5.2,43.257l-.612,6.711,22.262-.054c-.772,7.563-11.078,13.235-23.487,13.1l-.08.959L.752,91.244v.079l-.026.24L.7,91.83l-.027.292v.054a1.025,1.025,0,0,1-.026.293C.539,93.507.38,95.238.113,98.008c-.4,4.42.346,5.938.879,5.272,8.655-11.051,20.638-41.3,36.136-36.11,17.949,6.019,15.818-23.46,22.955-14.619C68.1,62.51,99.868,30.768,92.225,33.085Zm-74.67-2.1a2.956,2.956,0,0,1-3.222,2.663H14.28a2.956,2.956,0,0,1-2.663-3.222l.266-2.77a2.976,2.976,0,0,1,3.222-2.663l.054.027a2.969,2.969,0,0,1,2.663,3.2Z"/>
  </Svg>
);

const LogoLink = styled(Link)`
  text-decoration: none;
  color: var(--main-color);

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }

  &:hover {
    color: var(--accent-color);
    cursor: pointer;
  }
`;

const Go = styled(NavLink)`
  text-decoration: none;
  color: var(--main-color);
  font-size: 1rem;
  font-family: var(--inter);
  font-weight: 700;
  margin-left: 2rem;
  padding: 10px 0;
  transition: all 0.2s ease-in-out;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }

  &:hover {
    color: var(--accent-color);
  }

  &.active {
    color: var(--accent-color);
  }

  @media ${device.mobileL} {
    margin-left: 1rem;
  }
`;

function Header() {
  return (
    <Container>
      <Wrapper>
        <LogoLink to="/">
          <Logo/>
        </LogoLink>
        <div>
          <Go to="/about">about me</Go>
          <Go to="/contact">contact</Go>
        </div>
      </Wrapper>
    </Container>
  );
}

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../rules/device';
import Icon from './Icon';

const EndContainer = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    margin-bottom: 0;
  }

  @media ${device.mobileL} {
    margin-bottom: 1rem;
  }
`;

const EndCaption = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 3.4em;
  letter-spacing: -2px;
  line-height: 1.1;
  font-weight: 700;
  margin: 4rem 0 4rem 0;
  text-align: center;

  @media ${device.tablet} {
    margin: 3rem 0 4rem 0;
  }

  @media ${device.mobileL} {
    text-align: left;
    font-size: 2.8rem;
    justify-content: flex-start;
    text-align: left;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Svg = styled(Icon)`
  width: 30px;
  height: 30px;
  margin-right: 1rem;
`;

const BackIcon = ({ className }) => ( 
  <Svg viewBox="0 0 24 24" className={className} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <polyline points="15 6 9 12 15 18" />
  </Svg>
);

const MailIcon = ({ className }) => ( 
  <Svg viewBox="0 0 24 24" className={className} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="3" y="5" width="18" height="14" rx="2" />
    <polyline points="3 7 12 13 21 7" />
  </Svg>
);

const BackButton = styled(Link)`
  color: var(--accent-color);
  font-family: var(--inter);
  text-decoration: none;
  border: 2px solid var(--accent-color);
  border-radius: 5px;
  padding: 0.9rem 1.5rem 0.9rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  margin: 0 1rem;

  &:hover {
    color: var(--main-color);
    border: 2px solid var(--main-color);
  }

  @media ${device.tablet} { 
    margin: 0 0 2rem 0;
  }

  @media ${device.mobileL} {
    flex-grow: 1;
    margin-bottom: 1rem;
    font-weight: 600;
  }
`;

const ContactButton = styled(Link)`
  color: #fff;
  font-family: var(--inter);
  text-decoration: none;
  background-color: var(--accent-color);
  border-radius: 5px;
  padding: 1rem 1.5rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  margin: 0 1rem;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  @media ${device.tablet} { 
    margin: 0 0 2rem 0;
  }

  @media ${device.mobileL} {
    flex-grow: 1;
    margin-bottom: 1rem;
    font-weight: 600;
  }
`;

function DesignFooter() {
  return (
    <EndContainer>
      <EndCaption>
        You've reached the end.
        <br></br>
        What would you like to do?
      </EndCaption>
      <ButtonContainer>
        <BackButton to="/">
          <BackIcon/>
          Back to my portfolio
        </BackButton>
        <ContactButton to="/contact">
          <MailIcon/>
          Get contact info
        </ContactButton>
      </ButtonContainer>
    </EndContainer>
  );
}

export default DesignFooter;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../rules/device';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 6rem 2rem;
  font-weight: 700;
  font-size: 3.4rem;
  letter-spacing: -2px;
  line-height: 1.1;
  text-align: center;

    @media ${device.laptop} {
      width: 100%;
    }

    @media ${device.tablet} {
      width: 100%;
      flex-direction: column;
    }

    @media ${device.mobileL} {
      margin: 2rem 1rem;
      text-align: left;
      font-size: 2.8rem;
    }
`;

const ContactButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ContactButton = styled(Link)`
  color: #fff;
  font-family: var(--inter);
  text-decoration: none;
  background-color: var(--accent-color);
  border-radius: 5px;
  padding: 1rem 2rem;
  font-weight: 700;
  font-size: 1.1rem;
  position: relative;
  transition: all 0.2s ease;
  letter-spacing: 0;
  text-align: center;
  margin: 3rem 0 4rem 0;
  

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  @media ${device.tablet} { 
    margin-bottom: 4rem;
  }

  @media ${device.mobileL} {
    width: 100%;
    margin: 2rem 0 4rem 0;
    font-weight: 600;
  }
`;

function HomePreFooter() {
  return (
    <Container>
      <Wrapper>
        <>
          Like what you see and want to work together? Let's talk.
        </>
        <ContactButtonContainer>
          <ContactButton to="/contact">Get in touch</ContactButton>
        </ContactButtonContainer>
      </Wrapper>
    </Container>
  );
}

export default HomePreFooter;

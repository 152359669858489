import React from 'react';
import { Link } from 'react-router-dom'; 
import styled from 'styled-components';
import { device } from '../rules/device';

const Container = styled(Link)`
  border-radius: 5px;
  height: 440px;
  width: 440px;
  flex-shrink: 0;
  margin: 0 1rem 2rem 1rem;
  background-size: 105%;
  background-position: center;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:hover {
    opacity: 0.8;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
  }

  @media ${device.mobileL} {
    width: 100%;
    height: calc(100vw);
    background-size: cover;
    background-position: top left;

    &:hover, :active {
    opacity: 1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    }
  }
`;

function Card(props) {
  return (
    <Container
      to={props.link}
      style={{
        backgroundImage: 'url(' + props.thumbnail + ')', 
      }}>
    </Container>
  );
}

export default Card;

import React from 'react';

function NotFound() {
  return (
    <div>
      404 Not Found | Oops, something went wrong.
    </div>
  );
}

export default NotFound;

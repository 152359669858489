import React from 'react';
import styled from 'styled-components';
import { device } from '../rules/device';
import Icon from './Icon';

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: #f5f5f5;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobileL} {
    padding: 1rem;
    flex-direction: column;
  }
`;

const Svg = styled(Icon)`
  width: 90px;
  height: 65px;
  color: #888;

  @media ${device.mobileL} {
    margin-top: 1rem;
  }
`;

const Logo = ({ className }) => ( 
  <Svg viewBox="0 0 1340.889 658.21" className={className}>   
    <g>
      <g>
        <path fill="currentColor" d="M679.062,479.879a12.9,12.9,0,0,0-1.382.062,12.436,12.436,0,0,0-1.357-.062H627.752a89.1,89.1,0,0,0-72.029,141.6H486.042c-6.1,0-11.058-5.589-11.058-12.464V516.585h40.461a18.347,18.347,0,1,0,0-36.693H474.984V432.85a18.347,18.347,0,0,0-36.693,0v47.029h-69.9a89.017,89.017,0,0,0-70.769,35.006,89.154,89.154,0,1,0-3.975,113.176,107.152,107.152,0,0,1-18.7-38,52.445,52.445,0,1,1,4.33-23.374v.049c-.012.526-.012,1.04-.012,1.566,0,.245-.013.489-.013.734s.013.5.013.746c0,.55,0,1.088.012,1.639q-.038.88-.11,1.761c1.15,30.333,18.494,50.05,18.494,50.05l.012.013a89.072,89.072,0,0,0,70.72,34.932h34.187a18.347,18.347,0,0,0,0-36.694H368.389A52.5,52.5,0,0,1,316,571.418v-.049c-.025-.269-.037-.551-.037-.832-.012-.5-.025-1-.025-1.5,0-.159,0-.306-.012-.453l.025-1.015c.012-.293.024-.587.049-.881a52.505,52.505,0,0,1,52.385-50.111h69.9v92.443c0,23.069,15.521,42.479,36.388,47.751a46.507,46.507,0,0,0,11.363,1.407h193.02a18.232,18.232,0,0,0,12.965-5.37,16.806,16.806,0,0,0,1.737-1.994,17.992,17.992,0,0,0,2.532-4.672c.208-.562.379-1.137.538-1.724a18.279,18.279,0,0,0,.575-4.587v-141.6A18.354,18.354,0,0,0,679.062,479.879Zm-18.347,141.6H627.752a52.454,52.454,0,0,1,0-104.907h32.963Z"/>
        <path fill="currentColor" d="M954.291,433.406a18.347,18.347,0,0,0-18.347-18.347h-.012a18.347,18.347,0,1,0,18.359,18.347Z"/>
        <path fill="currentColor" d="M948.28,484.081a18.327,18.327,0,0,0-11.668-4.195h-62.2a18.346,18.346,0,0,0-18.347,18.346V621.486H811.511a52.513,52.513,0,0,1-52.447-52.447V498.232a18.347,18.347,0,0,0-36.694,0v70.807a89.255,89.255,0,0,0,83.062,88.933h.012c2.006.134,4.025.208,6.067.208h62.905a18.345,18.345,0,0,0,18.347-18.347V516.579h25.5V639.845a18.347,18.347,0,0,0,36.694,0v-141.6A18.353,18.353,0,0,0,948.28,484.081Z"/>
        <path fill="currentColor" d="M1251.736,472.631h-.11a89.244,89.244,0,0,0-89.141,89.141v59.713h-24.952V498.231a18.353,18.353,0,0,0-18.346-18.346,13.561,13.561,0,0,0-1.395.06,12.694,12.694,0,0,0-1.357-.06h-48.571a89.147,89.147,0,0,0,0,178.294h112.3a3.276,3.276,0,0,0,.343-.012,3.033,3.033,0,0,0,.33.012,18.346,18.346,0,0,0,18.347-18.347v-78.06a52.513,52.513,0,0,1,52.447-52.447h.11a52.5,52.5,0,0,1,52.448,51.738q-.037.587-.037,1.174v77.6a18.347,18.347,0,0,0,36.694,0V594.7c.036.416.048.831.048,1.26V561.772A89.246,89.246,0,0,0,1251.736,472.631Zm-150.9,148.854h-32.976a52.454,52.454,0,0,1,0-104.907h32.976Z"/>
      </g>
      <path fill="currentColor" d="M586.9,210.545c-48.637,14.744-394.35,43.892-298.431-17.285,101.171-64.4-174.042-56.6-125.745-86.6C230.683,64.3,128.157-11.964,68.674,1.592c-5.761,1.187-10.506,4.067-12.2,22.2C55.286,37.859,54.44,45.823,54.1,50.229a7.37,7.37,0,0,1-.169.847v1.016l-.17.17v.678L33.087,275.28l-3.9,42.7,141.674-.337c-4.915,48.128-70.5,84.223-149.469,83.377l-.509,6.1L4.788,580.658v.508l-.17,1.525-.169,1.7-.17,1.864v.339a6.375,6.375,0,0,1-.169,1.864c-.677,6.609-1.7,17.624-3.39,35.249-2.541,28.131,2.2,37.791,5.593,33.554,55.076-70.329,131.337-262.842,229.966-229.8,114.22,38.3,100.663-149.3,146.08-93.038C433.366,397.806,635.541,195.8,586.9,210.545ZM111.719,197.156A18.81,18.81,0,0,1,91.213,214.1h-.339a18.811,18.811,0,0,1-16.946-20.5l1.7-17.626a18.93,18.93,0,0,1,20.5-16.946l.339.169a18.9,18.9,0,0,1,16.947,20.337Z"/>
    </g>
  </Svg>
);

const Copyright = styled.div`
  color: #888;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -1;

  @media ${device.mobileL} {
    margin: 2rem 1rem;
  }
`;

function Footer() {
  return (
    <Container>
      <Wrapper>
        <Logo/>
        <Copyright>
          2021 © All rights reserved
        </Copyright>
      </Wrapper>      
    </Container>
  );
}

export default Footer;

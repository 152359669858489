import React from 'react';
import styled from 'styled-components';
import { device } from '../rules/device';

import Card from './Card';

import hellowThumb from '../img/hellow_2.png';
import playThumb from '../img/play.png';
import pannorThumb from '../img/pannor_3.jpg';
import honukaThumb from '../img/honuka.png';
import klim from '../img/klim.png';
import flamingo from '../img/flamingo.png';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  @media ${device.mobileL} {
    padding: 1rem;
  }
`;

function Portfolio({ id }) {
  return (
    <Container id={id}>
      <Wrapper>
        <Card
          thumbnail={hellowThumb}
          link="/design/hellow"
          label="design"
        />
        <Card
          thumbnail={playThumb}
          link="/design/play"
          label="design"
        />
        <Card
          thumbnail={pannorThumb}
          link="/design/pannor"
          label="design"
        />
        <Card
          thumbnail={honukaThumb}
          link="/design/honuka"
          label="design"
        />
        <Card
          thumbnail={klim}
          link="/design/klim"
          label="design"
        />
        <Card
          thumbnail={flamingo}
          link="/design/flamingo"
          label="design"
        />
      </Wrapper>
    </Container>
  );
}

export default Portfolio;
